import { render, staticRenderFns } from "./detailedOne.vue?vue&type=template&id=36e2da73&scoped=true&"
import script from "./detailedOne.vue?vue&type=script&lang=js&"
export * from "./detailedOne.vue?vue&type=script&lang=js&"
import style0 from "./detailedOne.vue?vue&type=style&index=0&id=36e2da73&scoped=scoped&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36e2da73",
  null
  
)

export default component.exports